<app-auth-wrapper>
  <div class="signup-form">
    <h2 class="title">Sign Up</h2>

    <app-validation-input
      [label]="'Enter your name'"
      [placeholder]="'The Witcher'"
      [type]="'text-required'"
      [appearance]="'legacy'"
      [errorMessage]="'You must enter your name'"
      (value)="handleName($event)"
      (valid)="handleNameValid($event)"
    ></app-validation-input>

    <app-validation-input
      [label]="'Enter your email'"
      [placeholder]="'pat@example.com'"
      [type]="'email'"
      [appearance]="'legacy'"
      (value)="handleEmail($event)"
      (valid)="handleEmailValid($event)"
    ></app-validation-input>

    <app-validation-input
      [label]="'Enter your password'"
      [type]="'password-validation'"
      [appearance]="'legacy'"
      [hide]="'true'"
      (value)="handlePassword($event)"
      (valid)="handlePasswordValid($event)"
    ></app-validation-input>

    <app-validation-input
      [label]="'Confirm your password'"
      [type]="'password-confirm'"
      [appearance]="'legacy'"
      [errorMessage]="'Password does not match'"
      [hide]="'true'"
      [password]="password"
      (valid)="handlePasswordConfirmValid($event)"
    ></app-validation-input>

    <div class="button-wrapper">
      <button
        mat-raised-button
        class="primary-button"
        (click)="sigup()"
        [disabled]="disabled"
      >
        Sign up
      </button>
      <button mat-raised-button routerLink="/login">Back to login</button>
    </div>
  </div>
</app-auth-wrapper>
