<div class="navbar-container">
  <ng-content></ng-content>

  <app-search></app-search>

  <div class="navbar-end">
    <a href="https://www.buymeacoffee.com/c4c19055e" target="_blank"><img
        src="https://cdn.buymeacoffee.com/buttons/default-orange.png" alt="Buy Me A Coffee" height="41" width="174"></a>

    <div class="notification-button">
      <mat-icon matBadge="2" matBadgeColor="warn" fontSet="material-icons-outlined">
        notifications
      </mat-icon>
    </div>

    <ng-container *ngIf="isAuthenticated">
      <app-current-user></app-current-user>
    </ng-container>

    <ng-container *ngIf="!isAuthenticated">
      <div class="button-wrapper">
        <button mat-flat-button class="primary-button" routerLink="/login">
          Log in
        </button>
      </div>
    </ng-container>
  </div>
</div>
