<div
  class="video-item-container"
  *ngIf="video && owner && isAdded !== undefined"
>
  <mat-card class="video-card-item" routerLink="/watch/{{ video.id }}">
    <div class="thumbnail-wrapper">
      <img src="{{ video.thumbnail }}" />
      <div class="thumbnail-overlay">
        <div
          [matTooltip]="getTooltipMessage()"
          matTooltipPosition="left"
          class="watch-later-button"
          (click)="toggleAddingToWatchLater($event)"
        >
          <mat-icon
            *ngIf="!isAdded"
            style="background-color: black; color: white;"
            >watch_later</mat-icon
          >
          <mat-icon
            *ngIf="isAdded"
            style="background-color: black; color: white;"
            >check</mat-icon
          >
        </div>
      </div>
    </div>

    <div class="video-item-footer">
      <div class="video-info">
        <span
          class="video-title"
          matTooltip="{{ video.title }}"
          matTooltipClass="tooltip-without-truncated"
          >{{ video.title }}</span
        >
        <span
          class="video-owner"
          *ngIf="type === 'card'"
          routerLink="channel/{{ owner.id }}"
          >By {{ owner.name }}
        </span>
        <span class="video-views"
          >{{ video.views }} views • {{ createdDuration }}</span
        >
      </div>
      <div class="video-settings" *ngIf="showSettings">
        <button
          mat-icon-button
          class="icon-button"
          matTooltip="Actions"
          (click)="toggleMenu($event)"
        >
          <mat-icon fontSet="material-icons-outlined">more_vert</mat-icon>
        </button>

        <app-menu-wrapper [hidden]="hidden">
          <span class="popover-item" (click)="openEditingVideoDialog($event)">
            <mat-icon fontSet="material-icons-outlined">create</mat-icon>
            Edit
          </span>
          <span
            class="popover-item-warning"
            (click)="openConfirmationDialog($event)"
          >
            <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
            Remove
          </span>
        </app-menu-wrapper>
      </div>
    </div>
  </mat-card>
</div>
