<div class="confirmation-dialog-container">
  <h1 mat-dialog-title [innerHTML]="data.title"></h1>
  <div mat-dialog-content>
    <p [innerHTML]="data.message"></p>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <button mat-button (click)="onCancelClick()">Cancel</button>
    <button mat-flat-button color="warn" [mat-dialog-close]="'remove'">
      Remove
    </button>
  </div>
</div>
