<mat-expansion-panel class="uploading-item" *ngIf="file && status !== 'Cancel'">
  <mat-expansion-panel-header
    class="uploading-item-header"
    [collapsedHeight]="'80px'"
    [expandedHeight]="'70px'"
  >
    <mat-panel-title class="file-name">
      <img class="header-icon" src="assets/clapperboard.svg" />
      <span [matTooltip]="fileName" [matTooltipPosition]="'above'">
        {{ fileName }}
      </span>
    </mat-panel-title>
    <mat-panel-description>
      <div class="upload-progress">
        <ngb-progressbar
          *ngIf="status === 'Pending'"
          type="primary"
          [value]="100"
          [height]="'16px'"
          [striped]="true"
          [animated]="true"
        ></ngb-progressbar>

        <ngb-progressbar
          *ngIf="status === 'Progress' || status === 'Success'"
          type="primary"
          [value]="currentProgress"
          [height]="'16px'"
          [showValue]="true"
        ></ngb-progressbar>

        <ngb-progressbar
          *ngIf="status === 'Error'"
          type="danger"
          [value]="100"
          [height]="'16px'"
          >Error!</ngb-progressbar
        >
      </div>

      <div class="options">
        <button
          class="option-button"
          mat-icon-button
          (click)="upload()"
          [disabled]="status !== 'Pending'"
        >
          <mat-icon fontSet="material-icons-outlined">cloud_upload</mat-icon>
        </button>
        <button class="option-button" mat-icon-button (click)="cancel()">
          <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
        </button>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <div class="title-wrapper">
    <span class="config-title">Video Title</span>

    <app-validation-input
      class="title-field"
      [label]="'Title'"
      [type]="'text'"
      [appearance]="'legacy'"
      [required]="false"
      (value)="handleInput($event, 'title')"
    ></app-validation-input>
  </div>

  <div class="tags-wrapper">
    <span class="config-title">Tags</span>
    <mat-form-field class="chip-field" appearance="legacy">
      <mat-chip-list #chipList aria-label="Tags">
        <mat-chip
          *ngFor="let tag of tags"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="removeTag(tag)"
        >
          {{ tag }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          placeholder="Tags"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="addTag($event)"
        />
      </mat-chip-list>
    </mat-form-field>
  </div>

  <div class="description-wrapper">
    <span class="config-title">Description</span>
    <mat-form-field class="description-field" appearance="legacy">
      <mat-label>Description</mat-label>
      <textarea
        matInput
        (input)="handleInput($event, 'description')"
      ></textarea>
    </mat-form-field>
  </div>
</mat-expansion-panel>
