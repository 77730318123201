<div class="comment-item-container" *ngIf="owner">
  <div class="comment-item">
    <div class="owner">
      <app-avatar
        class="owner-avatar"
        [avatarBase64]="owner.avatar"
        [size]="48"
      ></app-avatar>

      <div class="content">
        <div class="owner-info">
          <span class="name" [innerHTML]="owner.name"></span>
          <span class="time" [innerHTML]="createdDuration"></span>
        </div>
        <span class="comment-content" [innerHTML]="comment.content"></span>
      </div>
    </div>
  </div>
</div>
