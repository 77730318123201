<app-auth-wrapper>
  <div class="login-form-column">
    <h2 class="title">Login</h2>

    <app-validation-input
      [label]="'Enter your email'"
      [placeholder]="'pat@example.com'"
      [type]="'email'"
      [appearance]="'legacy'"
      (value)="handleEmail($event)"
    ></app-validation-input>

    <app-validation-input
      [label]="'Enter your password'"
      [type]="'password'"
      [appearance]="'legacy'"
      [errorMessage]="'You must enter a password'"
      [hide]="'true'"
      (value)="handlePassword($event)"
    ></app-validation-input>

    <div class="login-options">
      <mat-checkbox
        class="example-margin"
        [(ngModel)]="rememberMe"
        color="primary"
      >
        Remember me
      </mat-checkbox>

      <a class="forgot-password" routerLink="/forgot-password"
        >Forgot password?</a
      >
    </div>

    <div class="recaptcha-container"><div id="recaptcha"></div></div>

    <div class="button-wrapper">
      <button mat-raised-button class="primary-button" (click)="login()">
        Log in
      </button>
      <button mat-raised-button routerLink="/signup">Sign up</button>
    </div>

    <!-- <div class="divider-with-text">
      <span></span>
      <span>OR</span>
      <span></span>
    </div>

    <div class="alternative-login">
      <button mat-stroked-button class="google-button">
        <img src="assets/google-icon.svg" />
        Google
      </button>
    </div> -->
  </div>
</app-auth-wrapper>
