<div class="following-videos-section-container" *ngIf="videos && user">
  <div class="section-title-wrapper">
    <div class="section-title" [innerHTML]="user.name"></div>
    <button
      mat-stroked-button
      class="section-view-all"
      routerLink="/channel/{{ user.id }}"
    >
      View all
    </button>
  </div>
  <div class="section-content">
    <ng-container *ngFor="let video of videos">
      <app-video-item
        [type]="'card'"
        [video]="video"
        [me]="me"
      ></app-video-item>
    </ng-container>
  </div>
</div>
