<div
  class="channel-sidenav-container"
  *ngIf="user"
  [@openClose]="visible ? 'open' : 'close'"
>
  <ng-container *ngIf="visible">
    <div class="channel-info">
      <app-avatar [avatarBase64]="user.avatar" [size]="48"></app-avatar>
      <div class="channel-info-text">
        <div class="channel-name" [innerHTML]="user.name"></div>
        <div class="channel-followers" [innerHTML]="followersText"></div>
      </div>
    </div>

    <div class="channel-nav">
      <span
        class="side-menu-item"
        [class.menu-item-active]="active === 'videos'"
        (click)="setActiveState('videos')"
      >
        <mat-icon fontSet="material-icons-outlined">video_library</mat-icon>
        Videos
      </span>
      <span
        class="side-menu-item"
        [class.menu-item-active]="active === 'channels'"
        (click)="setActiveState('channels')"
      >
        <mat-icon fontSet="material-icons-outlined">group</mat-icon>
        Channels
      </span>
    </div>

    <button
      mat-button
      class="follow-button accent-button"
      [class.followed-button]="isFollowed"
      *ngIf="showFollowButton"
      (click)="toggleFollow($event)"
      [innerHTML]="followButtonText"
    ></button>
  </ng-container>

  <div class="toggle-button show-on-top" (click)="toggleChannelSidenav()">
    <mat-icon *ngIf="!visible">keyboard_arrow_right</mat-icon>
    <mat-icon *ngIf="visible">keyboard_arrow_left</mat-icon>
  </div>
</div>
