<div class="comment-container">
  <div class="comment-box">
    <mat-form-field>
      <mat-label>Leave a comment</mat-label>
      <textarea
        #commentInput
        matInput
        placeholder="Share your thoughts"
        (input)="handleInput($event)"
      ></textarea>
    </mat-form-field>
  </div>
  <div class="buttons-wrapper">
    <button mat-button class="cancel-button" (click)="clearComment()">
      Cancel
    </button>
    <button
      mat-flat-button
      class="primary-button"
      [disabled]="disabled"
      (click)="post()"
    >
      Post
    </button>
  </div>

  <div class="comment-list" *ngIf="comments">
    <div class="comment" *ngFor="let comment of comments">
      <app-comment-item [comment]="comment"></app-comment-item>
    </div>
  </div>
</div>
