<app-auth-wrapper>
  <div class="forgot-password-form">
    <h2 class="title">Enter your email below to reset password</h2>

    <app-validation-input
      [label]="'Enter your email'"
      [placeholder]="'pat@example.com'"
      [type]="'email'"
      [appearance]="'legacy'"
      (value)="handleEmail($event)"
    ></app-validation-input>

    <div class="button-wrapper">
      <button mat-raised-button class="primary-button" (click)="reset()">
        Reset
      </button>
      <button mat-raised-button routerLink="/login">Back to login</button>
    </div>
  </div>
</app-auth-wrapper>
