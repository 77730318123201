<div class="channel-header-container">
  <div class="channel-info">
    <app-avatar [avatarBase64]="user.avatar" [size]="60"></app-avatar>
    <div class="info-text">
      <span class="channel-name">{{ user.name }}</span>
      <span class="channel-followers">{{ user.followers }} followers</span>
    </div>
  </div>
  <button mat-flat-button class="accent-button">Follow</button>
</div>
