<div class="current-user-container" *ngIf="me$ | async as me">
  <div class="current-user-wrapper">
    <app-avatar [avatarBase64]="me.avatar" [size]="36"></app-avatar>
    <div class="current-user">{{ me.name }}</div>
  </div>

  <div class="current-user-dropdown">
    <span class="popover-item" routerLink="/channel/{{ me.id }}">
      <mat-icon fontSet="material-icons-outlined">person</mat-icon>
      My channel
    </span>
    <span class="popover-item" routerLink="/settings">
      <mat-icon fontSet="material-icons-outlined">settings</mat-icon>
      Settings
    </span>
    <div class="divider"></div>
    <span class="popover-item" (click)="logout()">
      <mat-icon fontSet="material-icons-outlined">exit_to_app</mat-icon>
      Logout
    </span>
  </div>
</div>
