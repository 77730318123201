<div class="uploading-container" *ngIf="queueObjects">
  <div class="uploading-wrapper">
    <div class="item" *ngFor="let item of queueObjects">
      <app-uploading-item
        [file]="item"
        [controller]="controller"
      ></app-uploading-item>
    </div>
  </div>
</div>
