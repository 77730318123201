<div class="search-container">
  <form class="search-form">
    <div class="search-input">
      <div class="prefix-icon">
        <mat-icon>search</mat-icon>
      </div>

      <input #searchInput class="search-input" type="text" placeholder="Search for..."
        (input)="handleSearchInput($event)" />

      <ng-container *ngIf="searchInput.value as text">
        <div class="suffix-icon" [hidden]="!text">
          <ng-container *ngIf="loading">
            <div class="loading">
              <mat-progress-spinner color="primary" mode="indeterminate" diameter="24">
              </mat-progress-spinner>
            </div>
          </ng-container>
          <ng-container *ngIf="!loading">
            <mat-icon class="close" (click)="clearSearch()">close</mat-icon>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="results && searchInput.value">
        <div class="search-results">
          <div class="message">
            Results for keyword: <span>{{ searchInput.value }}</span>
          </div>
          <div class="divider"></div>
          <mat-list *ngIf="results.length > 0">
            <div class="result-item" *ngFor="let result of results">
              <mat-list-item (click)="clearSearch()" routerLink="/watch/{{ result.id }}">
                <img src="{{ result.thumbnail }}" />
                {{ result.title }}
              </mat-list-item>
              <div class="divider"></div>
            </div>
          </mat-list>
        </div>
      </ng-container>
    </div>
  </form>
</div>