import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-wrapper',
  templateUrl: './auth-wrapper.component.html',
  styleUrls: ['./auth-wrapper.component.scss'],
})
export class AuthWrapperComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
